import React from 'react';
import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgrHllBur4Qcg7kWSkjUMUk_REndSfEgk",
  authDomain: "aleksander-dda55.firebaseapp.com",
  databaseURL: "https://aleksander-dda55-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aleksander-dda55",
  storageBucket: "aleksander-dda55.appspot.com",
  messagingSenderId: "327965276277",
  appId: "1:327965276277:web:d23fb9b9926a19ca9de03c",
  measurementId: "G-3N9R47JT2L"
};

// Initialize Firebase //
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    <div className="App">


      <header className="App-header">


        
        <a href="https://bestill.timma.no/studiobekk">
        <button className='mainButton' onClick={()=> {

        console.log("test")
        logEvent(analytics, "test")}
        }>
        
          Bestill time!
        
        
        </button>
        </a>
      </header>

      
    </div>
  );
}

export default App;
